import React from "react";
import PropTypes from "prop-types";
import { kebabCase } from "lodash";
import { Helmet } from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";

export const BlogPostTemplate = ({ release, helmet }) => {
  return (
    <div className="section">
      {helmet || ""}
      <div className="container content">
        <div className="columns">
          <div className="homepage-release">
            <img
              src={release.frontmatter.featuredimage.childImageSharp.fluid.src}
            />
            <div className="text">
              <h1>{release.frontmatter.title}</h1>
              <HTMLContent className="content" content={release.html} />
              <div className="links">
                {release.frontmatter.spotify && (
                  <span>
                    <a href={release.frontmatter.spotify}>spotify</a> |{" "}
                  </span>
                )}
                {release.frontmatter.applemusic && (
                  <span>
                    <a href={release.frontmatter.applemusic}>apple music</a> |{" "}
                  </span>
                )}
                {release.frontmatter.soundcloud && (
                  <span>
                    <a href={release.frontmatter.soundcloud}>soundcloud</a>
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
};

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <BlogPostTemplate
        contentComponent={HTMLContent}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        release={post}
      />
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        templateKey
        spotify
        applemusic
        soundcloud
        date(formatString: "MMMM DD, YYYY")
        featuredimage {
          childImageSharp {
            fluid(maxWidth: 400, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
